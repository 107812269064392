import React from 'react';
import './template.css';
import ChartHistogram from '../components/charts/histogram';
import ChartDimension from '../components/charts/dimension';
import ChartAverage from '../components/charts/average';
import DiagnosisTable from '../components/examination/tabs/diagnosis/diagnosis-table';
import ChartBenchmark from '../components/charts/benchmark';
import ChartLabel from '../components/charts/labels';
import i18n from '../i18n';

/**
 * Renders the template using methods for all pages
 */
export default function renderTemplate(data, chartData, tableParameters) {
  return (
    <React.Fragment>
      <div className="print-container">
        {page1(data, chartData)}
        {page2(data, tableParameters)}
        {page4(data, chartData)}
        {page5(data, chartData)}
      </div>
    </React.Fragment>
  );
}

/**
 * The header of all pages
 */
function header() {
  return (
    <div className="header">
      <div className="info">
        <h1 style={{ color: '#FFFFFF', visibility: 'visible', zIndex: '99' }}>
          MOBILE MOTION LAB ÁLLAPOTFELMÉRÉS
        </h1>
      </div>
      <div className="logo">
        <h1></h1>
      </div>
    </div>
  );
}

/**
 * The footer of all pages
 */
function footer(data, pageNumber) {
  return (
    <div className="footer">
      <p className="footer-right">
        {`${data.patient.firstName}, ${data.patient.lastName}, ${data.exam.type
          }, ${data.evaluation.date}`}
      </p>
      <p className="footer-center">{pageNumber}</p>
    </div>
  );
}

/**
 * The first page
 */
function page1(data, chartData) {
  console.log(data)
  return (
    <div id="page-1" className="page">
      {header()}
      <div className="content">
        <h1 className="name">
          {`${data.patient.firstName},${data.patient.lastName}`}
        </h1>
        <div className="block">
          <p>{`Vizsgálat időpontja: ${data.exam.date}`}</p>
          <p className="smaller italics">
            {`Mérés azonosító: ${data.exam.id}`}
          </p>
        </div>
        <div className="block">
          <div className="tabulator">
            <p>Testmagasság:</p>
            <p>{`${data.patient.height} cm`}</p>
          </div>
          <div className="tabulator">
            <p>Testsúly:</p>
            <p>{`${data.patient.weight} kg`}</p>
          </div>
          <div className="tabulator">
            <p>BMI:</p>
            <p>{data.patient.BMI || ''}</p>
          </div>

        </div>
        <div className="block">
          <p className="bolder">Megkeresés oka, panasz</p>
          <p className="multiline">{data.panasz === "" ? data.exam.status : data.panasz}</p>
          <p className="bolder">Kórelőzmény</p>
          <p className="multiline">{data.korelozmeny}</p>
          <p className="bolder">Ellenjavallatok</p>
          <p className="multiline">{data.ellenjavallat}</p>
        </div>
      </div>
      {footer(data, 1)}
    </div>
  );
}

/**
 * The second page
 */
function page2(data, tableParameters) {
  return (
    <div id="page-2" className="page">
      {header()}
      <div className="content">
        <div className="block">
          <h2>Mozgás mutatók</h2>
          <DiagnosisTable parameters={tableParameters} />
          <p className="smaller italics">
            diff: eltérés a bal-jobb oldal között (*)
            <br />
            dimenzió: a mutató mértékegysége
            <br />
            Bal referencia tartomány: bal lábon átlagos érték
            <br />
            Jobb referencia tartomány: jobb lábon átlagos érték
            <br />
          </p>
        </div>
      </div>
      {footer(data, 2)}
    </div>
  );
}

/**
 * The third page
 */
function page3(data, chartData) {
  return (
    <div id="page-3" className="page">
      {header()}
      <div className="content">
        <div className="block">
          <h2>Járásciklus szórása</h2>
          <p>
            V = Vertikális, S = szagittális, H = Horizontális tengelyek
            irányában (N/kg)
          </p>
          <div className="doublecharts">
            {chartData.dimensions.map(dimension => (
              <div key={dimension.name}>
                <ChartLabel
                  labelInfo={{
                    evaluationTime: data.evaluation.time,
                    movingAverage: data.evaluation.movingAverage,
                    chartName: dimension.name
                  }}
                  labelType="dimensions"
                />
                <div className="chart">
                  <ChartDimension
                    dimension={dimension}
                    dimensionDomain={chartData.dimensionsDomain}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {footer(data, 3)}
    </div>
  );
}

/**
 * The fourth page
 */
function page4(data, chartData) {
  return (
    <div id="page-4" className="page">
      {header()}
      <div className="content">
        <div className="block">
          <h2>Járásciklus (bal és jobb végtag összehasonlítása)</h2>
          {chartData.averages.map(average => (
            <div className="average-chart" key={average.name}>
              <ChartLabel
                labelInfo={{
                  evaluationTime: data.evaluation.time,
                  movingAverage: data.evaluation.movingAverage,
                  chartName: average.name
                }}
                labelType="averages"
              />
              <div className="chart">
                <ChartAverage
                  average={average}
                  averageDomain={chartData.averagesDomain}
                  fontSize={8}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {footer(data, 4)}
    </div>
  );
}

/**
 * The fifth page
 */
function page5(data, chartData) {
  return (
      <div id="page-5" className="page">
        {header()}
        <div className="content">
          <div className="block">
          <p className="bolder">Statikus vizsgálatok</p>
          <p className="multiline">{data.staty}</p>
          <p className="bolder">Dinamikus vizsgálatok</p>
          <p className="multiline">{data.dinamy}</p>
          <p className="bolder">Járás vizsgálat</p>
          <p className="multiline">
          Járás elemzés - 2 km/h: {data.walk2} <br />
          Járás elemzés - 4 km/h: {data.walk4} <br />
          Futás elemzés - 8 km/h:  {data.walk8} <br />
          Futás elemzés - 10 km/h:  {data.walk10} <br />
          Egyéb járás elemzés:  {data.walke} <br />
        </p>
          <p className="bolder">Speciális tesztek</p>
          <p className="multiline">{data.special}</p>
          <p className="bolder">Vélemény, terápiás javaslat</p>
          <p className="multiline">{data.opinion}</p>
          </div>
        </div>
        {footer(data, 4)}
      </div>
  );
}

function renderDiagnosis(diagnosis) {
  if (diagnosis) {
    return diagnosis.map(d => {
      if (d.value && d.value !== '') {
        return (
          <p className="diagnosis-result" key={d.name}>
            <span className="place-name">{i18n.t('diagnosisInfo.' + d.name.replace(/ /g, ''))}: </span>
            {`${d.value}`}
          </p>
        );
      }
      return null;
    });
  }
  return null;
}
