import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

import ExaminationView from './examination-view';
import Loader from '../../components/loader';
import NotFound from '../../components/not-found';

class Examination extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }).isRequired,
    app: PropTypes.shape({
      pairedId: PropTypes.string
    }),
    examinations: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      examinations: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    patients: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      patients: PropTypes.arrayOf(PropTypes.any).isRequired
    }),
    progress: PropTypes.shape({}),
    getExamination: PropTypes.func.isRequired,
    getPatient: PropTypes.func.isRequired,
    startExamination: PropTypes.func.isRequired,
    stopExamination: PropTypes.func.isRequired,
    getProgress: PropTypes.func.isRequired,
    pairDevice: PropTypes.func.isRequired,
    resendData: PropTypes.func.isRequired,
    cleanExaminationProgress: PropTypes.func.isRequired
  };

  static subRoutes = [
    'Overview',
    'Interval',
    'Evaluation',
    'Benchmark',
    'Diagnosis'
  ];

  static getYears(datestring) {
    const date1 = moment(datestring);
    const date2 = moment();
    const diff = date2.diff(date1, 'years');
    return diff;
  }

  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      isRunningExamModalOpen: false,
      isConfirmOpen: false, // The opened status of the confirm modal
      confirmFunction: null, // The function which the modal calls if confirmed
      confirmMessage: null, // The message of the confirm modal,
      isPatientModalOpen: false,
      asanaLoading: false,
      asanaDone: false,
      examDetails: true,
      formData: [],
      showform: false,
      examId: 0,
      formId: 6,
    };
    this.changeForm = this.changeForm.bind(this)
    this.changeFormValue = this.changeFormValue.bind(this);
    this.setActiveRoute = this.setActiveRoute.bind(this);
    this.openConfirm = this.openConfirm.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.openExamModal = this.openExamModal.bind(this);
    this.closeExamModal = this.closeExamModal.bind(this);
    this.startExam = this.startExam.bind(this);
    this.stopExam = this.stopExam.bind(this);
    this.openPatientModal = this.openPatientModal.bind(this);
    this.closePatientModal = this.closePatientModal.bind(this);
    this.setAsanaSuccess = this.setAsanaSuccess.bind(this);
    this.setAsanaLoading = this.setAsanaLoading.bind(this);
    this.changeExamDet = this.changeExamDet.bind(this);
    this.fetchExamDet = this.fetchExamDet.bind(this);
  }

  async componentDidMount() {
    const { getExamination, match, examinations } = this.props;
    const examId = match && match.params && match.params.id;
    // if the exam is not yet in the exams state (i.e. on page reload)
    // or or the results are not yet lazy loaded even though it has data
    // => load that single exam
    this.setState({
      examId: examId
    });
    const examination =
      examinations &&
      examinations.examinations &&
      examinations.examinations.find(e => e.id === examId);

    if (!examination || (examination.hasData && !examination.results)) {
      getExamination(examId);

    }
    this.fetchExamDet(examId, this.state.formId);

  }

  changeFormValue(index, value, option = false) {
    console.log(index, value, option)
    if(option) {
      //var lst = this.state.fields;
      //lst[index].options[ind].value = !lst[index].options[ind].value
      var tmp = this.state.formData;
      tmp[index].options[value].value = !tmp[index].options[value].value
      this.setState({ formData: tmp });
    } else {
      var tmp = this.state.formData;
      tmp[index].value = value
      this.setState({ formData: tmp });
    }
  }

  changeForm(value, formid) {
    this.setState({
      showform: value,
      formId: formid
    }, function() {
      if(value) {
        this.fetchExamDet(this.state.examId, formid)
      }
    });
  }

  changeExamDet(value) {

    const that = this;

    let formData = new FormData();
    formData.append('formexamid', this.state.examId);
    formData.append('saveformid', this.state.formId);
    formData.append('saveform', JSON.stringify(this.state.formData));

    fetch('https://menstage.kingstudio.hu/api2/'
    ,{
      headers : { 
        'Accept': 'application/json'
       },
      method: "post",
      body: formData
    }
    
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson)
        if(myJson.formid == "6") {
          that.setState({
            examDetails: true
          });
        }
        window.location.reload(true)
      });

  }

  fetchExamDet(examId, formid) {
    const that = this;
    this.setState({ formData: []});

    fetch('https://menstage.kingstudio.hu/api2/?helpid='+examId
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        if(myJson.remotehelp) {
          that.setState({ asanaDone: true });
        }
      });

    fetch('https://menstage.kingstudio.hu/api2/?examid='+examId+'&formid='+formid
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        console.log(JSON.parse(myJson.jsn));
        //that.setState({ examDetails: formid == 6 ? myJson.data : false, formData: JSON.parse(myJson.jsn)});
        that.setState({ formData: JSON.parse(myJson.jsn)});
      });
  }

  getSelectedExam() {
    const { match, examinations } = this.props;
    const examId = match && match.params && match.params.id;
    return (
      examinations &&
      examinations.examinations &&
      examinations.examinations.find(e => e.id.toString() === examId.toString())
    );
  }

  getPatientOfExam() {
    const { patients, getPatient } = this.props;
    const examination = this.getSelectedExam();

    if (!examination || !examination.patientId) return null;

    const patient =
      patients &&
      patients.patients &&
      patients.patients.find(
        p => p.id.toString() === examination.patientId.toString()
      );

    if (!patient && !patients.isFetching) {
      getPatient(examination.patientId);
    }

    return patient;
  }

  setActiveRoute(route) {
    const { location } = this.props;
    if (
      location &&
      location.pathname &&
      location.pathname.split('/')[3].toUpperCase() === route.toUpperCase()
    ) {
      return 'active';
    }
    return '';
  }

  startExam(examId) {
    const { startExamination } = this.props;

    // start the examination
    startExamination(examId);

    this.setState({
      isRunningExamModalOpen: true
    });
  }

  stopExam(examId) {
    const { stopExamination } = this.props;
    stopExamination(examId);
  }

  openExamModal() {
    this.setState({
      isRunningExamModalOpen: true
    });
  }

  closeExamModal(examId) {
    const { cleanExaminationProgress } = this.props;
    cleanExaminationProgress(examId);
    this.setState({
      isRunningExamModalOpen: false
    });
  }

  openEditModal() {
    this.setState({
      isEditModalOpen: true
    });
  }

  closeEditModal() {
    this.setState({
      isEditModalOpen: false
    });
  }

  // Opening the confirm modal
  // The passed function and message will be the props of the modal
  openConfirm(confirmFunction, confirmMessage) {
    this.setState({
      isConfirmOpen: true,
      confirmFunction,
      confirmMessage
    });
  }

  // Closing the confirm modal
  closeConfirm() {
    this.setState({
      isConfirmOpen: false
    });
  }

  setAsanaLoading() {
    this.setState({
      asanaLoading: true
    });
  }

  setAsanaSuccess() {
    this.setState({
      asanaDone: true
    });
  }

  openPatientModal() {
    this.setState({
      isPatientModalOpen: true
    });
  }

  closePatientModal() {
    this.setState({
      isPatientModalOpen: false
    });
  }

  render() {
    const {
      isEditModalOpen,
      isRunningExamModalOpen,
      isConfirmOpen,
      isPatientModalOpen,
      confirmFunction,
      confirmMessage
    } = this.state;
    const {
      app,
      examinations,
      patients,
      progress,
      pairDevice,
      resendData,
      getProgress
    } = this.props;
    const isFetching = examinations && examinations.isFetching;
    const errors = examinations && examinations.errors;

    if (isFetching) {
      return <Loader message={i18n.t(`loadingInfo.loadingExamination`)} />;
    }

    if (errors) {
      const errorMessage = errors && errors.message;
      if (errorMessage !== 'notEnoughRecords') {
        return (
          <NotFound
            message={i18n.t(`backendResponse.${errorMessage}`)}
            backTo="/examinations"
          />
        );
      }
    }

    const examination = this.getSelectedExam();
    if (!examination) {
      return null;
    }

    const isPatientsFetching = patients && patients.isFetching;
    const isPaired =
      app &&
      app.pairedId &&
      examination &&
      examination.id &&
      app.pairedId.toString() === examination.id.toString();
    const patient = this.getPatientOfExam();

    if (isPatientsFetching || !patient) {
      return <Loader message={i18n.t(`loadingInfo.loadingExamination`)} />;
    }

    const defaultModalValues = {
      weight: examination.weight || 0,
      height: examination.height || 0,
      date: moment(examination.date).format('YYYY-MM-DDTHH:mm') || 'n/a',
      dominantSide: (patient && patient.dominantSide) || '' || 'n/a',
      examType: examination.examType || 'n/a',
      comment: examination.comment || 'n/a',
      leftHip: examination.leftHip,
      rightHip: examination.rightHip,
      leftKnee: examination.leftKnee,
      rightKnee: examination.rightKnee,
      leftFoot: examination.leftFoot,
      rightFoot: examination.rightFoot,
      pelvis: examination.pelvis
    };

    const defaultModalValuesPatient = {
      firstName: patient && patient.firstName,
      lastName: patient && patient.lastName,
      dateOfBirth: patient && moment(patient.dateOfBirth).format('YYYY-MM-DD'),
      placeOfBirth: patient && patient.placeOfBirth,
      mothersName: patient && patient.mothersName,
      address: (patient && patient.address) || '',
      email: (patient && patient.email) || '',
      phone: (patient && patient.phone) || '',
      gender: patient && patient.gender,
      dominantSide: patient && patient.dominantSide
    };

    return (
      <ExaminationView
        patient={{ patient, isFetching: isPatientsFetching }}
        defaultModalValuesPatient={defaultModalValuesPatient}
        examination={{ examination, isFetching }}
        progress={progress}
        isRunningExamModalOpen={isRunningExamModalOpen}
        defaultModalValues={defaultModalValues}
        isEditModalOpen={isEditModalOpen}
        isPaired={isPaired}
        startExam={this.startExam}
        stopExam={this.stopExam}
        openExamModal={this.openExamModal}
        closeExamModal={this.closeExamModal}
        openEditModal={this.openEditModal}
        closeEditModal={this.closeEditModal}
        pairDevice={pairDevice}
        resendData={resendData}
        getYears={Examination.getYears}
        getProgress={getProgress}
        isConfirmOpen={isConfirmOpen}
        confirmFunction={confirmFunction}
        confirmMessage={confirmMessage}
        openConfirm={this.openConfirm}
        closeConfirm={this.closeConfirm}
        openPatientModal={this.openPatientModal}
        setAsanaLoading={this.setAsanaLoading}
        setAsanaSuccess={this.setAsanaSuccess}
        asanaDone={this.state.asanaDone}
        asanaLoading={this.state.asanaLoading}
        closePatientModal={this.closePatientModal}
        isPatientModalOpen={isPatientModalOpen}
        examId={this.state.examId}
        user={this.props.user}
        examDetails={this.state.examDetails}
        formData={this.state.formData}
        changeExamDet={this.changeExamDet}
        showform={this.state.showform}
        changeForm={this.changeForm}
        changeFormValue={this.changeFormValue}
        fetchExamDet={this.fetchExamDet}
      />
    );
  }
}

export default Examination;
