import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { signOut } from '../../../reducers/auth';
import Sidebar from './sidebar';

import i18n from '../../../i18n';

const mapStateToProps = ({ auth: { user } }) => ({
  user,
  navigationRoutes: [
    {
      label: i18n.t('navigation.recent'),
      link: '/recent'
    },
    {
      label: i18n.t('navigation.patients'),
      link: '/patients'
    },
    {
      label: i18n.t('navigation.examinations'),
      link: '/examinations'
    },
    {
      label: i18n.t('navigation.form'),
      link: '/generateform'
    }
  ]
});

export default withRouter(
  connect(
    mapStateToProps,
    { signOut }
  )(Sidebar)
);
