/* globals window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';
import ReportPDF from '../../../report-pdf';
import i18n from '../../../../i18n';


class DiagnosisBody extends Component {

  static propTypes = {
    evaluation: PropTypes.shape({
      leftHip: PropTypes.string,
      rightHip: PropTypes.string,
      leftKnee: PropTypes.string,
      rightKnee: PropTypes.string,
      leftFoot: PropTypes.string,
      rightFoot: PropTypes.string,
      pelvis: PropTypes.string,
      id: PropTypes.number.isRequired
    }),
    evaluationId: PropTypes.number,
    updateEvaluation: PropTypes.func.isRequired,
    patient: PropTypes.shape({}),
    examination: PropTypes.shape({}),
    interval: PropTypes.shape({}),
    benchmark: PropTypes.shape({}),
    printReport: PropTypes.func.isRequired
  };

  static medicalFields = {
    left: [
      {
        name: i18n.t('diagnosisInfo.hip'),
        key: 'hip',
        dictionary: []
      },
      {
        name: i18n.t('diagnosisInfo.knee'),
        key: 'knee',
        dictionary: []
      },
      {
        name: i18n.t('diagnosisInfo.foot'),
        dictionary: [],
        key: 'foot'
      }
    ],
    right: [
      {
        name: i18n.t('diagnosisInfo.hip'),
        dictionary: [],
        key: 'hip'
      },
      {
        name: i18n.t('diagnosisInfo.knee'),
        key: 'knee',
        dictionary: []
      },
      {
        name: i18n.t('diagnosisInfo.foot'),
        key: 'foot',
        dictionary: []
      }
    ],
    bottom: [
      {
        name: i18n.t('diagnosisInfo.pelvis'),
        key: 'pelvis',
        dictionary: []
      }
    ]
  };

  static selectOptions = {
    hip: [],
    knee: [
      {
        name: 'aclRupture',
        value: i18n.t('diagnosisOptions.knee.aclRupture')
      },
      {
        name: 'aclReplacement',
        value: i18n.t('diagnosisOptions.knee.aclReplacement')
      },
      {
        name: 'meniscusInjury',
        value: i18n.t('diagnosisOptions.knee.meniscusInjury')
      },
      {
        name: 'shinRibbonRupture',
        value: i18n.t('diagnosisOptions.knee.shinRibbonRupture')
      },
      {
        name: 'osteoarthritis',
        value: i18n.t('diagnosisOptions.knee.osteoarthritis')
      }
    ],
    foot: [
      {
        name: 'ankleDescent',
        value: i18n.t('diagnosisOptions.foot.ankleDescent')
      },
      {
        name: 'ankleLigamentRupture',
        value: i18n.t('diagnosisOptions.foot.ankleLigamentRupture')
      },
      {
        name: 'ankleLigamentPartialRupture',
        value: i18n.t('diagnosisOptions.foot.ankleLigamentPartialRupture')
      }
    ],
    pelvis: []
  };

  constructor(props) {
    super(props);
    this.state = {
      leftHip: '',
      rightHip: '',
      leftKnee: '',
      rightKnee: '',
      leftFoot: '',
      rightFoot: '',
      pelvis: '',
      shouldRenderPDF: false,
      e6: [],
      e7: [],
      e8: [],
      e9: [],
      e10: [],
      e11: [],
      e12: [],
      e13: []
    };
  }

  componentDidMount() {
    const { evaluation } = this.props;

    const that = this;
    console.log("examid")
    console.log(this.props.examination.id);

    //6-12
    fetch('https://menstage.kingstudio.hu/api2/?examid='+this.props.examination.id+'&formid='+6
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        that.setState({ e6: JSON.parse(myJson.jsn)});
      });

    fetch('https://menstage.kingstudio.hu/api2/?examid='+this.props.examination.id+'&formid='+12
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        that.setState({ e12: JSON.parse(myJson.jsn)});
      });

      fetch('https://menstage.kingstudio.hu/api2/?examid='+this.props.examination.id+'&formid='+13
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        that.setState({ e13: JSON.parse(myJson.jsn)});
      });

      fetch('https://menstage.kingstudio.hu/api2/?examid='+this.props.examination.id+'&formid='+6
      ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      }
      )
        .then(function(response){
          console.log(response)
          return response.json();
        })
        .then(function(myJson) {
          that.setState({ e6: JSON.parse(myJson.jsn)});
        });

        fetch('https://menstage.kingstudio.hu/api2/?examid='+this.props.examination.id+'&formid='+11
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            console.log(response)
            return response.json();
          })
          .then(function(myJson) {
            that.setState({ e11: JSON.parse(myJson.jsn)});
          });

          fetch('https://menstage.kingstudio.hu/api2/?examid='+this.props.examination.id+'&formid='+10
          ,{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
          }
          )
            .then(function(response){
              console.log(response)
              return response.json();
            })
            .then(function(myJson) {
              that.setState({ e10: JSON.parse(myJson.jsn)});
            });

            fetch('https://menstage.kingstudio.hu/api2/?examid='+this.props.examination.id+'&formid='+7
            ,{
              headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
               }
            }
            )
              .then(function(response){
                console.log(response)
                return response.json();
              })
              .then(function(myJson) {
                that.setState({ e7: JSON.parse(myJson.jsn)});
              });

              fetch('https://menstage.kingstudio.hu/api2/?examid='+this.props.examination.id+'&formid='+8
              ,{
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                 }
              }
              )
                .then(function(response){
                  console.log(response)
                  return response.json();
                })
                .then(function(myJson) {
                  that.setState({ e8: JSON.parse(myJson.jsn)});
                });

                fetch('https://menstage.kingstudio.hu/api2/?examid='+this.props.examination.id+'&formid='+9
                ,{
                  headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                   }
                }
                )
                  .then(function(response){
                    console.log(response)
                    return response.json();
                  })
                  .then(function(myJson) {
                    that.setState({ e9: JSON.parse(myJson.jsn)});
                  });

    if (!evaluation) return;

    this.setState({
      leftHip: evaluation.leftHip,
      rightHip: evaluation.rightHip,
      leftKnee: evaluation.leftKnee,
      rightKnee: evaluation.rightKnee,
      leftFoot: evaluation.leftFoot,
      rightFoot: evaluation.rightFoot,
      pelvis: evaluation.pelvis
    });
  }

  onSelectOptionChange(event, oldValue) {
    const { name, value: eventValue } = event.target;
    let newValue = eventValue;
    if (eventValue !== '') {
      if (oldValue) {
        newValue = `${oldValue}, ${eventValue}`;
      }
      this.handleChange(name, newValue);
    }
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  // eslint-disable-next-line class-methods-use-this
  generateSelectOptions(key) {
    return DiagnosisBody.selectOptions[key].map(selectItem => (
      <MenuItem
        key={selectItem.name}
        name={selectItem.name}
        value={selectItem.value}
      >
        {selectItem.value}
      </MenuItem>
    ));
  }

  // Hiding the rendered PDF if the user closes the browser's print modal
  loadPrint() {
    window.print();
    setTimeout(() => {
      window.close();
    }, 100);
    this.setState({
      shouldRenderPDF: false
    });
  }

  async saveEvaluation(options) {
    const {
      evaluationId,
      evaluation,
      printReport,
      updateEvaluation
    } = this.props;

    const {
      leftHip,
      rightHip,
      leftKnee,
      rightKnee,
      leftFoot,
      rightFoot,
      pelvis
    } = this.state;

    // update body parameters
    await updateEvaluation({
      ...evaluation,
      leftHip: leftHip || '',
      rightHip: rightHip || '',
      leftKnee: leftKnee || '',
      rightKnee: rightKnee || '',
      leftFoot: leftFoot || '',
      rightFoot: rightFoot || '',
      pelvis: pelvis || ''
    });

    // print report on backend
    if (options && options.print) {
      // generate PDF on backend
      printReport(evaluationId);
      // render hidden pdf section for local printing
      await this.setState({
        shouldRenderPDF: true
      });
      // delay opening the default print popup, otherwise the print api
      // request is not sent before the dialog open
      setTimeout(() => {
        this.loadPrint();
      }, 500);
    }
  }

  renderMedicalFields(side) {
    const { medicalFields } = DiagnosisBody;
    let fields;
    let prepos;
    let preposKey;

    switch (side) {
      case 'left':
        fields = medicalFields.left;
        prepos = i18n.t('diagnosisInfo.left');
        preposKey = 'left';
        break;
      case 'right':
        fields = medicalFields.right;
        prepos = i18n.t('diagnosisInfo.right');
        preposKey = 'right';
        break;
      case 'bottom':
        fields = medicalFields.bottom;
        prepos = '';
        preposKey = '';
        break;
      default:
        fields = [];
        prepos = '';
        preposKey = '';
    }

    return fields.map(field => {
      let { key } = field;
      const optionKey = key;

      if (preposKey) {
        key =
          preposKey.toLowerCase() + key.replace(/^./, str => str.toUpperCase());
      }

      const value = this.state[key]; // eslint-disable-line react/destructuring-assignment
      return (
        <React.Fragment key={key}>
          <p className="box-title">{i18n.t("diagnosisInfo."+preposKey.toLowerCase()+field.name)}</p>
          <div className="box">
            <textarea
              rows="10"
              type="text"
              value={value || ''}
              name={key}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
            />
            <FormControl fullWidth key={key}>
              <TextField
                select
                onChange={e => this.onSelectOptionChange(e, value)}
                value={i18n.t('diagnosisOptions.select')}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: '13pt' },
                }}
                name={key}
              >
                {this.generateSelectOptions(optionKey)}
              </TextField>
            </FormControl>
          </div>
        </React.Fragment>
      );
    });
  }

  renderPDF() {
    const { shouldRenderPDF } = this.state;
    const {
      patient,
      evaluation,
      examination,
      interval,
      benchmark
    } = this.props;
    if (shouldRenderPDF) {
      return (
        <ReportPDF
          patient={patient}
          panasz={this.state.e6.length > 2 ? this.state.e6[1].value : ""}
          korelozmeny={this.state.e7.length > 0 ? this.state.e7[0].value : ""}
          ellenjavallat={this.state.e11.length > 0 ? this.state.e11[0].value : ""}

          staty={this.state.e8.length > 0 ? this.state.e8[0].value : ""}
          dinamy={this.state.e10.length > 0 ? this.state.e10[0].value : ""}
          walk2={this.state.e13.length > 0 && this.state.e13[0].options && this.state.e13[0].options > 4 && this.state.e13[0].options[0].value ? (this.state.e13[1].value) : ""}
          walk4={this.state.e13.length > 0 && this.state.e13[0].options && this.state.e13[0].options > 4 && this.state.e13[0].options[1].value ? (this.state.e13[2].value) : ""}
          walk8={this.state.e13.length > 0 && this.state.e13[0].options && this.state.e13[0].options > 4 && this.state.e13[0].options[2].value ? (this.state.e13[3].value) : ""}
          walk10={this.state.e13.length > 0 && this.state.e13[0].options && this.state.e13[0].options > 4 && this.state.e13[0].options[3].value ? (this.state.e13[4].value) : ""}
          walke={this.state.e13.length > 0 && this.state.e13[0].options && this.state.e13[0].options > 4 && this.state.e13[0].options[4].value ? (this.state.e13[5].value) : ""}
          special={this.state.e9.length > 0 ? this.state.e9[0].value : ""}
          opinion={this.state.e12.length > 0 ? this.state.e12[0].value : ""}

          evaluation={evaluation}
          examination={examination}
          interval={interval}
          benchmark={benchmark}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <div id="diagnosis">
        {
          <div className="report-to-print">{this.renderPDF()}</div>
        }
        { false ? <div />: 
        <div className="asdasd hide-print">
          {
            /*
          
          <div className="left-side">{this.renderMedicalFields('left')}</div>
          <div className="right-side">{this.renderMedicalFields('right')}</div>*/
          }
          <h3 style={{ fontSize: 16, fontWeight: "bold" }}>
          Vizsgálat adatai
          </h3>
          {
            this.state.e6.map((o) => (
              <div>
                <p>{o.name}: {o.value}</p>
              </div>
            ))
          }
          <h3 style={{ fontSize: 16, fontWeight: "bold" }}>
          Kórelőzmény
          </h3>
          {
            this.state.e7.map((o) => (
              <div>
                <p>{o.name}: {o.value}</p>
              </div>
            ))
          }
          
    
}

          <h3 style={{ fontSize: 16, fontWeight: "bold" }}>
          Statikus vizsgálatok
          </h3>
          {
            this.state.e8.map((o) => (
              <div>
                <p>{o.name}: {o.value}</p>
              </div>
            ))
          }
          <h3 style={{ fontSize: 16, fontWeight: "bold" }}>
          Speciális tesztek
          </h3>
          {
            this.state.e9.map((o) => (
              <div>
                <p>{o.name}: {o.value}</p>
              </div>
            ))
          }
          <h3 style={{ fontSize: 16, fontWeight: "bold" }}>
          Funkcionális vizsgálatok
          </h3>
          {
            this.state.e10.map((o) => (
              <div>
                <p>{o.name}: {o.value}</p>
              </div>
            ))
          }
          <h3 style={{ fontSize: 16, fontWeight: "bold" }}>
          Ellenjavallatok
          </h3>
          {
            this.state.e11.map((o) => (
              <div>
                <p>{o.name}: {o.value}</p>
              </div>
            ))
          }
          <h3 style={{ fontSize: 16, fontWeight: "bold" }}>
          Járás vizsgálat
          </h3>
          {
            this.state.e13.map((o) => (
              <div>
                <p>{o.name}: {o.value}</p>
              </div>
            ))
          }
          <h3 style={{ fontSize: 16, fontWeight: "bold" }}>
          Vélemény
          </h3>
          {
            this.state.e12.map((o) => (
              <div>
                <p>{o.name}: {o.value}</p>
              </div>
            ))
          }
          <div style={{ clear: "both" }}></div>
          
          <p>
            {
            //this.renderMedicalFields('bottom')
          }
          </p>
        </div>
  }
        <div className="report-buttons">
          { /*
          <button
            className="report-button"
            type="button"
            onClick={() => this.saveEvaluation()}
          >
            {i18n.t('diagnosisActions.saveDiagnosis')}
</button> */ }

          <button
            className="report-button"
            type="button"
            onClick={() => this.saveEvaluation({ print: true })}
          >
            {i18n.t('diagnosisActions.printReport')}
          </button>
        </div>
      </div>
    );
  }
}

export default DiagnosisBody;
