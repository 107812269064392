// reload .....
const config = {
  API_BASE_URL: "https://staging.mml.science/api/v1",
  API_BASE_URL_INTERNAL:
    "https://staging.mml.science/api/v1/",
  IMAGES_BASE_URL: process.env.REACT_APP_IMAGES_BASE_URL,
  SAGA_BUFFER_SIZE: process.env.REACT_APP_SAGA_BUFFER_SIZE
};

export default config;
